import allScreeningsDispatcher from '../../action';
import {
  COLUMN_SERVICES,
  FORMAT_LIST_TESTS,
  HS_PAXSCREENING_STATUS,
  SERVICE_TYPES,
  STATUS_DISPLAY_STATION,
  SUFFIX_LABNUMBER_TYPES,
  STATUS_STATION,
  CHECKED_TYPE,
  FIELD_ARRAY_VALUE,
  LIST_PROVIDER_SUFFIX_PREFIX,
  LIST_PROVIDER_UP_PREFIX,
} from '../../constants';
import {
  formatTestServices,
  formatCustomCategories,
  formatExpressTotalTestServices,
  formatMeasurementInSideListTest,
  generateCurrentLabNumber,
} from '../../utils';
import GeneralInformation from '../GeneralInformation';
import { StatusComponent } from '../StatusComponent';
import AddOnsItem from './AddOnsItem';
import PackageItem from './PackageItem';
import ServicesTable from './ServicesTable';
import Subsidy from './Subsidy';
import {
  ClipboardIcon,
  EditIcon,
  PrintIcon,
  CreateNewIcon,
  EyeIcon,
  CheckmarkCircleIcon,
  Add,
} from '@/assets/svg';
import { urlLabel } from '@/enum/PermissionEnum';
import { useAbortableEffect } from '@/helpers/useHookHelpers';
import ModalInputValue from '@/module/all-screenings/component/ViewPatientRegistration/ModalInputServices';
import { useCalculateCost } from '@/module/all-screenings/component/useCalculateCost';
import allUsersDispatcher from '@/module/all-users/action';
import { formatTotalPrice } from '@/module/checkin-patient-information/util';
import companyDispatcher from '@/module/company/action';
import { packageDispatcher } from '@/module/package-management';
import { formatData } from '@/module/package-management/utils';
import locationDispatcher from '@/module/setup/action';
import { IOSSwitch } from '@/module/setup/components/mainScreen.styled';
import { BILLING_TYPE } from '@/module/setup/constants';
import { isBillableProject } from '@/module/setup/utils';
import upcomingHSDispatcher from '@/module/upcoming-health-screening/action';
import CustomMenuActions from '@/new-components/CustomMenuActions';
import ModalAddOrEditSubsidy from '@/new-components/CustomModal/ModalAddOrEditSubsidy';
import ModalAssignLabNumber, {
  LAB_NUMBERS_MODES,
  LAB_PROVIDERS,
} from '@/new-components/CustomModal/ModalAssignLabNumber';
import ModalEditBillableAmount from '@/new-components/CustomModal/ModalEditBillableAmount';
import ModalPrintLabNumber from '@/new-components/CustomModal/ModalPrintLabNumber';
import ModalStaffType from '@/new-components/CustomModal/ModalStaffType';
import customToast from '@/new-components/CustomNotification';
import CustomPaperContainer from '@/new-components/CustomPaperContainer';
import CustomHeader from '@/new-components/CustomPaperContainer/CustomHeader';
import CustomTooltip from '@/new-components/CustomTooltip';
import { CircularProgress, IconButton, Menu } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TableCell from '@material-ui/core/TableCell';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { MoreVertRounded } from '@material-ui/icons';
import JsBarcode from 'jsbarcode';
import {
  capitalize,
  debounce,
  findIndex,
  get,
  isEmpty,
  isNil,
  lowerCase,
  upperCase,
} from 'lodash';
import moment from 'moment';
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

const useStyles = makeStyles({
  container: {
    display: 'flex',
  },

  rightContainer: {
    overflowY: 'scroll',
    overflowX: 'hidden',
    padding: '36px 8px',
    flex: '1',
    borderLeft: '1px solid #F1F1F1',
    height: 'calc(100vh - 222px)',
  },

  leftContainer: {
    overflow: 'scroll',
    padding: '16px 20px',
    display: 'flex',
    height: 'calc(100vh - 222px)',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 16,
    fontSize: 20,
    gap: 8,
    color: '#333333',
  },
  disabledSwitch: {
    pointerEvents: 'none',
    cursor: 'not-allowed',
  },
});

const CustomButton = withStyles({
  root: {
    minWidth: 90,
    height: 20,
    padding: 0,

    textTransform: 'unset',
  },
  label: {
    fontSize: 14,
  },
})(Button);

const ViewPatientRegistration = () => {
  const { editPackageData, paxScreeningData } = useSelector(
    (state) => state.upcomingHS
  );
  const [dataInfor, setDataInfor] = useState({});
  const [projectInfor, setProjectInfor] = useState({});

  const [labNumbersMode, setLabNumbersMode] = useState(
    LAB_NUMBERS_MODES.AutoAssign
  );
  const [currentLabNumber, setCurrentLabNumber] = useState('');
  const [currentLabProvider, setCurrentLabProvider] = useState('');
  const [totalLabNumbers, setTotalLabNumbers] = useState([]);
  const [totalTestCodes, setTotalTestCodes] = useState([]);

  const [showAlertLabNumber, setShowAlertLabNumber] = useState(false);
  const [showInputValueModal, setShowInputValueModal] = useState(false);
  const [showStaffType, setShowStaffType] = useState(false);
  const [showAssignLabNumber, setShowAssignLabNumber] = useState(false);

  const [selectedItem, setSelectedItem] = useState({});

  const [lstConsentIds, setLstConsentIds] = useState([]);
  const [lstStations, setLstStations] = useState([]);
  const [lifestyleSurvey, setLifestyleSurvey] = useState([]);
  const [customSurvey, setCustomSurvey] = useState([]);
  const [consetForms, setConsetForms] = useState([]);
  const [currentStaffType, setCurrentStaffType] = useState(null);
  const [hasChooseStaffType, setHasChooseStaffType] = useState(false);
  const [lstStaffTypeItems, setLstStaffTypeItems] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [errors, setErrors] = useState({});

  // const [paxCost, setPaxCost] = useState(null);
  // const [isPaxCostFetching, setIsPaxCostFetching] = useState(true);
  const [showEditBillableAmount, setShowEditBillableAmount] = useState(false);
  const [showEditSubsidyModal, setShowEditSubsidyModal] = useState(false);

  const [formValue, setFormValue] = useState({
    lstTests: [],
    lstProfiles: [],
    lstPackages: [],
    ignoredTests: [],
    ignoredProfiles: [],
    lstScreeningFields: [],
  });

  const [restrictedTestIds, setRestrictedTestIds] = useState([]);

  const location = useLocation();
  const history = useHistory();
  const apptData = get(location, 'state', {});
  const label = get(location, 'label', '');
  const isViewRegistration = get(apptData, 'isViewRegistration', false);
  const isScreeningProgress = get(apptData, 'isScreeningProgress', false);
  const isPatientRegistration = get(apptData, 'isPatientRegistration', false);
  const isFollowUp = get(apptData, 'isFollowUpAppointment', false);

  const [billableAmount, setBillableAmount] = useState(null);
  const [subsidy, setSubsidy] = useState(null);
  const [subsidyMode, setSubsidyMode] = useState('Add');
  const [subsidyNote, setSubsidyNote] = useState(null);
  const { paxCost, isPaxCostCalculating } = useCalculateCost({
    apptData,
    billableAmount,
    subsidy,
    lstTests: formValue?.lstTests,
    lstProfiles: formValue?.lstProfiles,
    lstPackages: formValue?.lstPackages,
    ignoredProfiles: formValue?.ignoredProfiles,
    ignoredTests: formValue?.ignoredTests,
    staffType: currentStaffType,
    projectInfo: projectInfor,
  });

  const isAfterRegistration = [
    HS_PAXSCREENING_STATUS.CheckIn,
    HS_PAXSCREENING_STATUS.InProgress,
    HS_PAXSCREENING_STATUS.Completed,
  ].includes(apptData.paxScreenStatus);

  const classes = useStyles();

  const generateConsentForm = (data) => {
    const newData = data.map((item) =>
      item?.consentId ? { ...item, id: item?.consentId } : { ...item }
    );
    return newData;
  };

  const generateParamsData = (paxData) => {
    const {
      paxScreeningId,
      lstPackages,
      lstProfiles,
      lstTests,
      projectId,
      ignoredProfiles,
      ignoredTests,
    } = paxData || {};
    let newListTest = [];
    lstTests.forEach((test) => (newListTest = newListTest.concat(test.items)));

    const paxInfo = {
      packageIds: lstPackages,
      profileIds: lstProfiles,
      testIds: newListTest,
    };

    let data = {};

    for (const property in paxInfo) {
      data[property] = formatTestServices(paxInfo[property]);
    }
    return {
      ...data,
      projectId,
      paxScreeningId,
      ignoredProfiles,
      ignoredTests,
    };
  };

  const formatParamsData = (data, projectId) => {
    return { price: data.price, id: data.id, projectId };
  };

  const onSubmit = () => {
    if (isAfterRegistration) {
      if (isPatientRegistration) {
        history.push({
          pathname: `/${urlLabel.aSScreeningProgress}`,
          state: {
            ...apptData,
            isPatientRegistration: false,
            isScreeningProgress: true,
          },
          label: 'Screening progress',
        });
      } else history.push(`/${urlLabel.allScreenings}`);
    } else {
      const { lstPackages, lstProfiles, lstTests } = formValue;
      let newListTest = [];
      lstTests.forEach(
        (test) => (newListTest = newListTest.concat(test.items))
      );
      newListTest = formatExpressTotalTestServices(newListTest);

      let newPatientData = {
        ...formValue,
        staffType: currentStaffType,
        lstPackages: lstPackages.map((test) =>
          formatParamsData(test, test.projectId)
        ),
        lstProfiles: lstProfiles.map((test) =>
          formatParamsData(test, test.projectId)
        ),
        lstTests: newListTest.map((test) =>
          formatParamsData(test, test.projectId)
        ),
      };

      let newParams = [];

      if (isFollowUp) {
        newPatientData = {
          ignoredTests: formValue.ignoredTests,
          ignoredProfiles: formValue.ignoredProfiles,
          lstPackages: lstPackages.map((test) =>
            formatParamsData(test, test.projectId)
          ),
          lstProfiles: lstProfiles.map((test) =>
            formatParamsData(test, test.projectId)
          ),
          lstTests: newListTest.map((test) =>
            formatParamsData(test, test.projectId)
          ),
        };

        newParams = {
          patientData: newPatientData,
        };
      } else {
        switch (projectInfor?.billingType) {
          case BILLING_TYPE.Eligible:
            if (!isNil(billableAmount)) {
              newPatientData.billableAmount = billableAmount;
            }
            break;
          case BILLING_TYPE.Default:
            if (!currentStaffType) {
              newPatientData.subsidy = subsidy;
              newPatientData.subsidyNote = subsidyNote;
            }
            break;
          default:
            break;
        }

        newParams = {
          consentData: { lstConsentIds },
          patientData: newPatientData,
        };
      }

      allScreeningsDispatcher.patientCheckIn(apptData.id, newParams, () => {
        customToast(
          'success',
          `Appointment for patient ${dataInfor.fullName} has been successfully updated.`,
          'Appointment updated'
        );
        history.push(`/${urlLabel.allScreenings}`);
        localStorage.removeItem('dataPaxScreening');
      });
    }
  };

  const onAssignLabNumber = (provider) => {
    allScreeningsDispatcher.generateLabNumber(
      apptData.id,
      provider,
      (isSuccess) => {
        if (isSuccess) {
          allScreeningsDispatcher.getData(apptData.id, (result) => {
            const { currentLabNumber, currentLabProvider, allLabNumbers } =
              result;
            allLabNumbers.reverse();
            setCurrentLabNumber(currentLabNumber);
            setCurrentLabProvider(currentLabProvider);
            setTotalLabNumbers(allLabNumbers);
          });
        }
      }
    );
  };

  const onSubmitLabNumber = async (newLabNumber) => {
    const { id: paxScreeningId } = apptData;

    allScreeningsDispatcher.createLabNumber(
      paxScreeningId,
      newLabNumber,
      (message) => {
        if (message) {
          setErrors({ labNumber: message });
        } else {
          allScreeningsDispatcher.getData(apptData.id, (result) => {
            const { currentLabNumber, currentLabProvider, allLabNumbers } =
              result;
            allLabNumbers.reverse();
            setCurrentLabNumber(currentLabNumber);
            setCurrentLabProvider(currentLabProvider);
            setTotalLabNumbers(allLabNumbers);
          });
        }
      }
    );
  };

  const onEditStaffType = (newStaffType) => {
    customToast('success', 'Update staff type successful');
    setCurrentStaffType(newStaffType);
    setShowStaffType(false);
  };

  const formatListLabNumberByProvider = (
    provider,
    labNumber,
    prefixTypes = []
  ) => {
    let listLabNumber = [];
    listLabNumber = prefixTypes?.map((type) =>
      provider === LAB_PROVIDERS.Innoquest
        ? {
            currentLabNumber: `${labNumber}${type}`,
            text: `${labNumber} - ${type}`,
          }
        : {
            currentLabNumber: `${labNumber}${type}`,
            text: `${labNumber}${type}`,
          }
    );
    return listLabNumber;
  };

  const handlePrintLabNumber = async (type) => {
    const popup = window.open();
    let listLabNumber = [];
    const suffixPrefix = LIST_PROVIDER_SUFFIX_PREFIX[currentLabProvider];
    const upPrefix = LIST_PROVIDER_UP_PREFIX[currentLabProvider];

    const newCurrentLabNumber = generateCurrentLabNumber(
      currentLabNumber,
      currentLabProvider
    );

    switch (type) {
      case SUFFIX_LABNUMBER_TYPES.SE_GF_SUFFIX:
        listLabNumber = formatListLabNumberByProvider(
          currentLabProvider,
          newCurrentLabNumber,
          suffixPrefix
        );
        break;
      case SUFFIX_LABNUMBER_TYPES.UP_SUFFIX:
        listLabNumber = formatListLabNumberByProvider(
          currentLabProvider,
          newCurrentLabNumber,
          upPrefix
        );
        break;
      default:
        listLabNumber = [
          {
            currentLabNumber: `${newCurrentLabNumber}`,
            text: `${newCurrentLabNumber}`,
          },
        ];
        break;
    }

    renderBarCode(dataInfor, listLabNumber, popup);
  };

  const renderBarCode = (userData, listData, popup) => {
    let { fullName, identityNumber, dateOfBirth, gender, healthScreeningDate } =
      userData;

    const head = `<head>
    <style>
    body {
      width: 54mm;
      max-width: 54mm;
      display: flex;
      margin: 32px;
    }
    .barcode-size {
      max-width: 54mm;
      width: 54mm;
      height: 20mm;
    }
    .container {
      max-width: 54mm;
      overflow: hidden;
      margin-bottom: 24px;
    }
    .position {
      margin: 32px;
    }
    .text-center {
      width: 54mm;
      max-width: 54mm;
      text-align: center;
    }
    .text-left {
      margin-bottom: 0;
      text-align: left;
    }
    .text-labnumber{
      font-family: monospace;
      display: inline-block;
      transform: scale(1.2, 0.9);
      margin-bottom: 0;
    }
    p{
      font-size: 14px;
      margin: 0;
      margin-bottom: 8px;
      word-wrap: break-word;
    }
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 8px;
    }

    .text-lab-provider {
      margin-bottom: 0;
    }
    </style>
    </head>`;

    const body = `<body></body>`;

    popup.document.head.innerHTML = head;
    popup.document.body.innerHTML = body;

    if (!isEmpty(listData)) {
      const barCode = popup.document.createElement('div');
      barCode.id = 'barcode';
      barCode.className = 'text-center';

      listData.forEach((item) => {
        const textContent = popup.document.createElement('P');
        const textContent2 = popup.document.createElement('P');
        const textContentDate = popup.document.createElement('P');
        textContentDate.className = 'text-left';
        const textContentLabNumber = popup.document.createElement('P');
        textContentLabNumber.className = 'text-labnumber';
        const textContentLabProvider = popup.document.createElement('P');
        textContentLabProvider.className = 'text-lab-provider';
        const containerHeader = popup.document.createElement('DIV');
        containerHeader.className = 'header';

        const textnode = popup.document.createTextNode(`${fullName} `);
        const textnode2 = popup.document.createTextNode(
          `${identityNumber} - ${
            dateOfBirth && moment(dateOfBirth).format('DD MMM YYYY')
          } - ${gender}`
        );
        const textHealthScreeningDate = popup.document.createTextNode(
          ` ${moment(healthScreeningDate).utc().format('DD/MM/YYYY')} `
        );

        const textLabProvider = popup.document.createTextNode(
          upperCase(currentLabProvider)
        );

        const textLabNumber = popup.document.createTextNode(item.text);

        const container = popup.document.createElement('div');
        container.id = `container-barcode-${item.currentLabNumber}`;
        container.className = 'container';

        const canvas = popup.document.createElement('canvas');
        canvas.className = 'barcode-size';
        canvas.id = `canvas-barcode-${item.currentLabNumber}`;

        JsBarcode(canvas, item.currentLabNumber, {
          // text: item.text,
          // textPosition: 'top',
          margin: 0,
          marginTop: 0,
          marginBottom: 10,
          marginRight: 10,
          marginLeft: 10,
          displayValue: false,
        });

        textContent.appendChild(textnode);
        textContent2.appendChild(textnode2);
        //Header
        textContentDate.appendChild(textHealthScreeningDate);
        textContentLabProvider.appendChild(textLabProvider);
        containerHeader.appendChild(textContentDate);
        containerHeader.appendChild(textContentLabProvider);

        // Body
        textContentLabNumber.appendChild(textLabNumber);

        // container.appendChild(textContentDate);
        container.appendChild(containerHeader);
        container.appendChild(textContentLabNumber);
        container.appendChild(canvas);
        container.appendChild(textContent);
        container.appendChild(textContent2);
        barCode.appendChild(container);
      });

      popup.document.body.appendChild(barCode);
    }
  };

  const onEditPackage = () => {
    history.push({
      pathname: `/${urlLabel.aSEditPackage}`,
      state: {
        ...apptData,
        ...formValue,
        lstConsentIds,
        isAfterRegistration,
        isRegisterPatient: true,

        billableAmount,
        staffType: currentStaffType,
        subsidy,
        subsidyNote,
      },
      label,
    });
  };

  const onPatientRegistration = () => {
    history.push({
      pathname: `/${urlLabel.aSPatientRegistration}`,
      state: {
        ...apptData,
        isPatientRegistration: true,
        isScreeningProgress: false,
      },
      label: 'Patient Registration',
    });
  };

  const fetchGetListTestStation = () => {
    const { id, status, healthScreeningId, patientId } = apptData;

    allScreeningsDispatcher.getAllListTestStation(
      {
        paxScreeningId: id,
        healthScreeningId,
        patientId,
        isCheckIn: status === HS_PAXSCREENING_STATUS.CheckIn,
      },
      ({ dataTests, lstStations }) => {
        const newStations = [...lstStations].map((it) => {
          // If station status is Awaiting and one test is included this station Done => station status = Ingrogress
          if (it.status === 'Awaiting') {
            const hasDoneTest = it?.lstTests?.find(
              (test) => test.status === 'Done'
            );
            if (hasDoneTest) return { ...it, status: 'InProgress' };
            return it;
          }
          return it;
        });

        setLstStations(newStations);
      }
    );
  };

  const onSumitMeasurementValue = (dataMeasurements, cbSuccess) => {
    const { id: paxScreeningId } = apptData;
    const { total, measurementType } = dataMeasurements;
    const queueId = total.map((item) => item.queueId)[0];
    const tests = total.map((it) => ({
      testId: it.testId,
      testValue: it.testValue,
      visualAucityType: it.visualAucityType,
    }));

    allScreeningsDispatcher.updateTestValue({
      category: 'Measurements',
      queueId,
      paxScreeningId,
      measurementType,
      tests,
      cb: (isSuccess) => {
        if (isSuccess) {
          customToast('success', 'Update test value success');
          fetchGetListTestStation();
        }
        cbSuccess && cbSuccess(isSuccess);
      },
    });
  };

  const onSumitOtherTestValue = (testValue, cbSuccess) => {
    if (!selectedItem) return;
    const { id: paxScreeningId } = apptData;
    const { queueId, testId, category, visualAucityType } = selectedItem;

    const tests = [
      {
        testId,
        testValue,
        visualAucityType,
      },
    ];
    allScreeningsDispatcher.updateTestValue({
      category,
      queueId,
      paxScreeningId,
      measurementType: 'Other',
      tests,
      cb: (isSuccess) => {
        if (isSuccess) {
          customToast('success', 'Update test value success');
          fetchGetListTestStation();
        }
        cbSuccess && cbSuccess(isSuccess);
      },
    });
  };
  const handleUpdateOptOut = (testItem, isShowMessage = true) => {
    if (!testItem) return;
    if (!testItem?.total) {
      const { queueId, testId } = testItem;

      const { id: paxScreeningId } = apptData;
      if (!queueId) {
        allScreeningsDispatcher.updateOptOutNoneStation(
          { paxScreeningId, testId },
          (isSuccess) => {
            if (isSuccess) {
              isShowMessage && customToast('success', 'Update optout success');
              fetchGetListTestStation();
            }
          }
        );
      } else {
        const testIds = [testId];
        allScreeningsDispatcher.updateOptOut(
          { queueId, testIds, paxScreeningId },
          (isSuccess) => {
            if (isSuccess) {
              isShowMessage && customToast('success', 'Update optout success');
              fetchGetListTestStation();
            }
          }
        );
      }
    } else {
      const queueId = testItem?.total[0].queueId;
      const testIds = testItem.total.map((item) => item.testId);
      const { id: paxScreeningId } = apptData;
      allScreeningsDispatcher.updateOptOut(
        { queueId, testIds, paxScreeningId },
        (isSuccess) => {
          if (isSuccess) {
            isShowMessage && customToast('success', 'Update optout success');
            fetchGetListTestStation();
          }
        }
      );
    }
  };

  const handleUpdateOptOutMeasurement = (data) => {
    if (data?.total) {
      return handleUpdateOptOut(data, false, false);
    } else {
      return handleUpdateOptOut(data);
    }
  };

  const handleUpdateOptIn = (testItem, isShowMessage = true) => {
    if (!testItem) return;
    if (!testItem?.total) {
      const { queueId, testId } = testItem;

      const { id: paxScreeningId } = apptData;
      if (!queueId) {
        allScreeningsDispatcher.updateOptInNoneStation(
          { paxScreeningId, testId },
          (isSuccess) => {
            if (isSuccess) {
              isShowMessage && customToast('success', 'Update optin success');
              fetchGetListTestStation();
            }
          }
        );
      } else {
        const testIds = [testId];
        allScreeningsDispatcher.updateOptIn(
          { queueId, testIds },
          (isSuccess) => {
            if (isSuccess) {
              isShowMessage && customToast('success', 'Update optin success');
              fetchGetListTestStation();
            }
          }
        );
      }
    } else {
      const queueId = testItem?.total[0].queueId;
      const testIds = testItem.total.map((item) => item.testId);

      allScreeningsDispatcher.updateOptIn({ queueId, testIds }, (isSuccess) => {
        if (isSuccess) {
          isShowMessage && customToast('success', 'Update optin success');
          fetchGetListTestStation();
        }
      });
    }
  };

  const handleUpdateOptInMeasurement = (data) => {
    if (data?.total) {
      return handleUpdateOptIn(data, false, false);
    } else {
      return handleUpdateOptIn(data);
    }
  };

  const handleClickDoneAll = (queueId, stationId) => {
    if (stationId === 'no-station') {
      allScreeningsDispatcher.makeDoneAllTestsNoneStation(
        { paxScreeningId: apptData?.id, category: null },
        (isSuccess) => {
          if (isSuccess) {
            customToast('success', 'Update all status successfully');
            fetchGetListTestStation();
          }
        }
      );
    } else {
      if (queueId) {
        allScreeningsDispatcher.makeDoneAllTestsByStationId(
          queueId,
          (isSuccess) => {
            if (isSuccess) {
              customToast('success', 'Update all status successfully');
              fetchGetListTestStation();
            }
          }
        );
      }
    }
  };
  const handleNotifyToPatient = (id) =>
    allScreeningsDispatcher.notifyPatient(id);

  const handleClickStatusToDone = (testItem) => {
    if (!testItem) return;

    const { queueId, testId } = testItem;
    const { id: paxScreeningId } = apptData;

    if (!queueId) {
      allScreeningsDispatcher.changeStatusToDoneNoneStation(
        { paxScreeningId, testId },
        (isSuccess) => {
          if (isSuccess) {
            customToast('success', 'Update status success');
            fetchGetListTestStation();
          }
        }
      );
    } else {
      allScreeningsDispatcher.changeStatusToDone(
        { queueId, testId },
        (isSuccess) => {
          if (isSuccess) {
            customToast('success', 'Update status success');
            fetchGetListTestStation();
          }
        }
      );
    }
  };

  useEffect(() => {
    if (apptData.id && apptData.projectId) {
      allScreeningsDispatcher.getData(apptData.id, (paxData) => {
        const {
          paxInfo,
          staffType,
          allLabNumbers,
          currentLabNumber,
          currentLabProvider,
          lstPackages,
          lstTests,
          lstProfiles,
          isSignedConsentForm,
          isCompletedSurvey,
          ignoredTests,
          ignoredProfiles,
          lstScreeningFields,
          date,
          status,
          pmCode,
          billableAmount,
          subsidy,
          subsidyNote,
        } = paxData;

        const filterScreeningField = formatCustomCategories(
          lstScreeningFields,
          FIELD_ARRAY_VALUE
        );

        const paramsConsent = generateParamsData({
          paxScreeningId: apptData.id,
          lstPackages,
          lstProfiles,
          lstTests,
          projectId: apptData.projectId,
          ignoredProfiles,
          ignoredTests,
        });

        const newLstTests = formatMeasurementInSideListTest(lstTests);

        if (['CheckInLocation', 'Upcoming'].includes(status)) {
          // Update consent form when edit test service
          allScreeningsDispatcher.getAllPaxConsents(paramsConsent, (result) => {
            const dataConsent = generateConsentForm(result);
            const lstConsentIds = dataConsent.map((item) => item.id);
            setLstConsentIds(lstConsentIds);
            setConsetForms(dataConsent);
          });
        } else {
          //View only consent form
          allScreeningsDispatcher.getConsentsByPatientID(
            apptData?.id,
            (result) => {
              const dataConsent = generateConsentForm(result);
              const lstConsentIds = dataConsent.map((item) => item.id);
              setLstConsentIds(lstConsentIds);
              setConsetForms(dataConsent);
            }
          );
        }
        setDataInfor({
          fullName: paxInfo.fullName,
          identityNumber: paxInfo.identityNumber,
          dateOfBirth: paxInfo.dateOfBirth,
          nationality: paxInfo.nationality,
          ethnicGroup: paxInfo.ethnicGroup,
          mobile: paxInfo.mobile,
          email: paxInfo.email,
          countryCode: paxInfo.countryCode,
          gender: paxInfo.gender,
          totalCost: apptData.totalCost,
          isSignedConsentForm,
          isCompletedSurvey,
          healthScreeningDate: date,
          paxScreenStatus: apptData?.paxScreenStatus,
          doneFollowUpTests: paxData?.doneFollowUpTests,
          pmCode,
        });

        // Get optional profile code in package
        if (!isEmpty(lstPackages)) {
          const packageId = lstPackages[0].id;
          packageDispatcher.getPackageById(packageId, (data) => {
            const { lstProfiles: lstPackagesProfiles } = data;
            const optionalProfilesCodes = lstPackagesProfiles.filter(
              (value) => {
                return (
                  !value.isRequired &&
                  ignoredProfiles.every((test) => {
                    return value.id !== test.profileId;
                  })
                );
              }
            );
            const testCodes = [
              ...lstPackages,
              ...optionalProfilesCodes,
              ...lstProfiles,
            ].map((it) => it.code);
            setTotalTestCodes(testCodes);
          });
        } else {
          const testCodes = [...lstPackages, ...lstProfiles].map(
            (it) => it.code
          );
          setTotalTestCodes(testCodes);
        }
        // Lab number
        allLabNumbers.reverse();
        setCurrentLabNumber(currentLabNumber);
        setCurrentLabProvider(currentLabProvider);
        setTotalLabNumbers(allLabNumbers);

        if (!apptData.isEditPackage) {
          setFormValue({
            projectId: apptData.projectId,
            lstTests: newLstTests,
            lstProfiles,
            lstPackages,
            ignoredTests,
            ignoredProfiles,
            lstScreeningFields,
          });

          upcomingHSDispatcher.setState('paxScreeningData', {
            projectId: apptData.projectId,
            lstTests: newLstTests,
            lstProfiles,
            lstPackages,
            ignoredTests,
            ignoredProfiles,
            lstScreeningFields,
          });
          // setPaxCost({
          //   totalCost: apptData.totalCost,
          //   remainingCost: paxData.nextPayment
          // });
          // setIsPaxCostFetching(false);
        }
        setCustomSurvey(filterScreeningField);

        // Get List staion
        fetchGetListTestStation();

        companyDispatcher.getProjectByID(apptData.projectId, (projectData) => {
          setProjectInfor(projectData);
          setLstStaffTypeItems(projectData?.lstStaffTypeItems);

          if (isBillableProject(projectData?.billingType)) {
            if (isAfterRegistration) {
              setBillableAmount(billableAmount);
            } else if (
              !apptData.hasEditPackage &&
              !isNil(apptData.billableAmount)
            ) {
              setBillableAmount(apptData.billableAmount);
            }
          } else {
            if (staffType || apptData.staffType) {
              setCurrentStaffType(apptData.staffType ?? staffType);
              setHasChooseStaffType(!isNil(apptData.staffType ?? staffType));
            } else {
              setSubsidy(apptData.subsidy ?? subsidy);
              setSubsidyNote(apptData.subsidyNote ?? subsidyNote);
            }
          }
        });
      });

      // Hadndle restricted test ids
      locationDispatcher.getDetailLocation(apptData.locationId, (result) => {
        const { restrictedTestIds } = result;
        setRestrictedTestIds(restrictedTestIds);
      });
    }
  }, [apptData]);

  useEffect(() => {
    if (editPackageData) {
      const {
        lstProfiles,
        lstPackages,
        ignoredTests,
        ignoredProfiles,
        projectId,
        lstScreeningFields,
      } = editPackageData;
      const {
        lstProfiles: lstOldProfiles,
        lstPackages: lstOldPackages,
        lstTests,
      } = paxScreeningData;
      setCustomSurvey(lstScreeningFields);

      // Compare with oldData to show new status
      // Hold data after edit package after check in location

      let newListTests = [];

      const lisOldTests = formatData({ lstTests });
      for (const [key, value] of Object.entries(editPackageData)) {
        if (FORMAT_LIST_TESTS[key] && !isEmpty(value)) {
          let newValues = value?.map((it) =>
            findIndex(lisOldTests[key], (test) => test.id === it.id) === -1
              ? { ...it, status: 'New' }
              : it
          );

          newListTests.push({
            category: FORMAT_LIST_TESTS[key],
            items: newValues,
          });
        }
      }
      newListTests = formatMeasurementInSideListTest(newListTests);

      const newLstProfiles = lstProfiles?.map((it) =>
        findIndex(lstOldProfiles, (test) => test.id === it.id) === -1
          ? { ...it, status: 'New' }
          : it
      );

      const newLstPackages = lstPackages?.map((it) =>
        findIndex(lstOldPackages, (test) => test.id === it.id) === -1
          ? { ...it, status: 'New' }
          : it
      );

      setFormValue({
        lstTests: newListTests,
        projectId,
        lstProfiles: newLstProfiles,
        lstPackages: newLstPackages,
        ignoredTests,
        ignoredProfiles,
        lstScreeningFields,
      });
      upcomingHSDispatcher.setState('paxScreeningData', {
        lstTests: newListTests,
        projectId,
        lstProfiles: newLstProfiles,
        lstPackages: newLstPackages,
        ignoredTests,
        ignoredProfiles,
        lstScreeningFields,
      });
    }
  }, [editPackageData]);

  useAbortableEffect((_isMounted) => {
    const { patientId, id: paxScreeningId } = apptData || {};

    allUsersDispatcher.getSurveyHistory(
      { patientId, paxScreeningId },
      (data = []) => {
        if (isEmpty(data)) return;
        _isMounted.current && setLifestyleSurvey(data);
      }
    );
  }, []);

  if (!location.state) history.push(`/${urlLabel.allScreenings}`);

  const renderTestValue = (testItem) => {
    const isTestsType = testItem.category === 'Tests';
    let value = '';

    switch (lowerCase(testItem.testCode)) {
      case SERVICE_TYPES.BMI:
        const weight = testItem.weight.testValue; // kilogram
        const height = testItem.height.testValue; // meter
        if (weight && height) {
          value = (+weight / (+height * +height)).toFixed(1);
        }
        break;

      case SERVICE_TYPES.ACUITY:
        let leftValue = 0;
        let rightValue = 0;
        const {
          visualAucityType,
          leftAided,
          leftUnaided,
          rightAided,
          rightUnaided,
        } = testItem;
        if (!visualAucityType || visualAucityType === CHECKED_TYPE.default)
          value = '';
        if (visualAucityType === CHECKED_TYPE.aided) {
          leftValue = leftAided.testValue || 0;
          rightValue = rightAided.testValue || 0;
        } else if (visualAucityType === CHECKED_TYPE.unAided) {
          leftValue = leftUnaided.testValue || 0;
          rightValue = rightUnaided.testValue || 0;
        }
        if (leftValue && rightValue)
          value = `L: 6/${leftValue} - R: 6/${rightValue}`;

        break;
      case SERVICE_TYPES.BLOOD_PRESSURE:
        const diastolic = testItem.diastolic.testValue; // mmHg
        const systolic = testItem.systolic.testValue; // mmHg
        const diastolic2 = testItem.diastolic2.testValue; // mmHg
        const systolic2 = testItem.systolic2.testValue; // mmHg
        if (systolic && diastolic) {
          value = (
            <div>
              <p>
                {systolic}/{diastolic}
              </p>
              {diastolic2 && systolic2 && (
                <p>
                  {systolic2}/{diastolic2}
                </p>
              )}
            </div>
          );
        }

        break;
      case SERVICE_TYPES.WAIST_HIP_RADIO:
        const waist = testItem.waist.testValue;
        const hip = testItem.hip.testValue;
        if (waist && hip) {
          value = (+waist / +hip).toFixed(2);
        }

        break;
      case SERVICE_TYPES.URINE_DIPSTICK:
        const UDSBlood = testItem.urineDipstickBlood.testValue;
        const UDSGlucose = testItem.urineDipstickGlucose.testValue;
        const UDSProtein = testItem.urineDipstickProtein.testValue;
        if (!UDSBlood && !UDSGlucose && !UDSProtein) value = '';
        else if (
          lowerCase(UDSBlood) === 'no' &&
          lowerCase(UDSGlucose) === 'no' &&
          lowerCase(UDSProtein) === 'no'
        ) {
          value = 'Negative';
        } else value = 'Positive';

        break;
      case SERVICE_TYPES.APR:
      case SERVICE_TYPES.PVT:
        const left = testItem.left.testValue;
        const right = testItem.right.testValue;
        if (left && right)
          value = (
            <>
              <p>
                L: <span>{capitalize(left)}</span>
              </p>
              <p>
                R: <span>{capitalize(right)}</span>
              </p>
            </>
          );

        break;
      case SERVICE_TYPES.ISHIHARA:
        value = capitalize(testItem.testValue);
        break;

      case SERVICE_TYPES.TONOMETRY: {
        const left = testItem.left.testValue;
        const right = testItem.right.testValue;
        if (left && right) {
          value = `L: ${left} - R: ${right}`;
        }
        break;
      }

      default:
        value = testItem.testValue;
        break;
    }

    return isEmpty(value) ? (
      <CustomButton
        color="primary"
        onClick={() => {
          setShowInputValueModal(true);
          setSelectedItem(testItem);
        }}
        disabled={isTestsType || testItem.status === STATUS_STATION.UnAssigned}
        startIcon={<CreateNewIcon />}
      >
        Add value
      </CustomButton>
    ) : (
      value
    );
  };

  const checkTestValue = (testItem) => {
    const isTestsType = testItem.category === 'Tests';
    let value = testItem.value;

    switch (lowerCase(testItem.testCode)) {
      case SERVICE_TYPES.BMI:
        const weight = testItem.weight.testValue; // kilogram
        const height = testItem.height.testValue; // meter
        if (weight && height) {
          value = (+weight / (+height * +height)).toFixed(1);
        }
        break;

      case SERVICE_TYPES.ACUITY:
        let leftValue = 0;
        let rightValue = 0;
        const {
          visualAucityType,
          leftAided,
          leftUnaided,
          rightAided,
          rightUnaided,
        } = testItem;
        if (!visualAucityType || visualAucityType === CHECKED_TYPE.default)
          value = '';
        if (visualAucityType === CHECKED_TYPE.aided) {
          leftValue = leftAided.testValue || 0;
          rightValue = rightAided.testValue || 0;
        } else if (visualAucityType === CHECKED_TYPE.unAided) {
          leftValue = leftUnaided.testValue || 0;
          rightValue = rightUnaided.testValue || 0;
        }
        if (leftValue && rightValue)
          value = (
            <>
              <p>
                Left: <span>6/{leftValue}</span>
              </p>
              <p>
                Right: <span>6/{rightValue}</span>
              </p>
            </>
          );

        break;
      case SERVICE_TYPES.BLOOD_PRESSURE:
        const diastolic = testItem.diastolic.testValue; // mmHg
        const systolic = testItem.systolic.testValue; // mmHg
        const diastolic2 = testItem.diastolic2.testValue; // mmHg
        const systolic2 = testItem.systolic2.testValue; // mmHg
        if (systolic && diastolic) {
          value = (
            <div>
              <p>
                {systolic}/{diastolic}
              </p>
              {diastolic2 && systolic2 && (
                <p>
                  {systolic2}/{diastolic2}
                </p>
              )}
            </div>
          );
        }

        break;
      case SERVICE_TYPES.WAIST_HIP_RADIO:
        const waist = testItem.waist.testValue;
        const hip = testItem.hip.testValue;
        if (waist && hip) {
          value = (+waist / +hip).toFixed(2);
        }

        break;
      case SERVICE_TYPES.URINE_DIPSTICK:
        const UDSBlood = testItem.urineDipstickBlood.testValue;
        const UDSGlucose = testItem.urineDipstickGlucose.testValue;
        const UDSProtein = testItem.urineDipstickProtein.testValue;
        if (!UDSBlood && !UDSGlucose && !UDSProtein) value = '';
        else if (
          lowerCase(UDSBlood) === 'no' &&
          lowerCase(UDSGlucose) === 'no' &&
          lowerCase(UDSProtein) === 'no'
        ) {
          value = 'Negative';
        } else value = 'Positive';
        break;
      case SERVICE_TYPES.APR:
      case SERVICE_TYPES.PVT:
        const left = testItem.left.testValue;
        const right = testItem.right.testValue;
        if (left && right)
          value = (
            <>
              <p>
                Left: <span>{capitalize(left)}</span>
              </p>
              <p>
                Right: <span>{capitalize(right)}</span>
              </p>
            </>
          );

        break;
      case SERVICE_TYPES.ISHIHARA:
        value = capitalize(testItem.testValue);
        break;

      case SERVICE_TYPES.TONOMETRY: {
        const left = testItem.left.testValue;
        const right = testItem.right.testValue;
        if (left && right) {
          value = `L: ${left} - R: ${right}`;
        }
        break;
      }

      default:
        value = testItem.testValue;
        break;
    }
    return !isEmpty(value);
  };
  const renderRow = (row, i) => (
    <>
      <TableCell>
        <CustomTooltip content={row.testName} />
      </TableCell>
      <TableCell>{row.category}</TableCell>
      <TableCell>
        <StatusComponent
          status={row.status}
          filterList={STATUS_DISPLAY_STATION}
        />
      </TableCell>
      <TableCell>{renderTestValue(row)}</TableCell>
      <TableCell>
        <IOSSwitch
          checked={row.isOptOut}
          color="primary"
          name="isOptOut"
          size="medium"
          disabled={row.status === STATUS_STATION.UnAssigned}
          onChange={() => {
            row.category === 'Measurements'
              ? row.isOptOut
                ? handleUpdateOptInMeasurement(row)
                : handleUpdateOptOutMeasurement(row)
              : row.isOptOut
              ? handleUpdateOptIn(row)
              : handleUpdateOptOut(row);
          }}
        />
      </TableCell>
      <TableCell>
        <IconButton
          disabled={isDisabledMenu(row)}
          onClick={(e) => {
            setAnchorEl(e.currentTarget);
            setSelectedItem(row);
          }}
        >
          <MoreVertRounded />
        </IconButton>
      </TableCell>
    </>
  );

  const newListProfiles = useMemo(() => {
    let newList = [];
    newList = [
      {
        category: 'Profiles',
        items: formValue.lstProfiles,
      },
    ];
    return newList.concat(formValue?.lstTests);
  }, [formValue]);

  const isDisabledMenu = (row) => {
    /*
      If this station or tests are pending
      If Measurement doesn't have value
      If Test is done
      If Other tests (except Measurement & Test ) are mandatory and don't have value
      If Other tests (except Measurement & Test ) are done and don't have value
    */
    const isPending = row.status === STATUS_STATION.UnAssigned;
    const isMeasumentNotValue =
      row.category === 'Measurements' && !checkTestValue(row);
    const isTestsDoneValue =
      row.category === 'Tests' && row.status === STATUS_STATION.Done;
    const isOtherValue =
      !['Tests', 'Measurements'].includes(row.category) &&
      (row.isMandatory || row.status === STATUS_STATION.Done) &&
      !checkTestValue(row);

    return isPending || isMeasumentNotValue || isTestsDoneValue || isOtherValue;
  };

  const renderHeaderButtons = () => (
    <>
      {!isFollowUp ? (
        <Button
          color="primary"
          variant="outlined"
          startIcon={<PrintIcon />}
          disabled={!currentLabNumber}
          onClick={() => setShowAlertLabNumber(true)}
        >
          Print label
        </Button>
      ) : null}

      {isScreeningProgress ? (
        <Button
          color="primary"
          variant="contained"
          startIcon={<EyeIcon />}
          onClick={onPatientRegistration}
        >
          View registration
        </Button>
      ) : (
        <>
          <Button
            color="primary"
            variant="outlined"
            startIcon={<Add width={20} height={20} />}
            onClick={() => {
              setShowEditSubsidyModal(true);
              setSubsidyMode('Add');
            }}
            hidden={
              isBillableProject(projectInfor?.billingType) ||
              currentStaffType ||
              isAfterRegistration ||
              !isNil(subsidy)
            }
          >
            Add subsidy
          </Button>

          {!isFollowUp ||
          (isFollowUp &&
            apptData.paxScreenStatus ===
              HS_PAXSCREENING_STATUS.CheckInLocation) ? (
            <Button
              color="primary"
              variant="contained"
              startIcon={<EditIcon />}
              onClick={onEditPackage}
              disabled={
                apptData.paxScreenStatus === HS_PAXSCREENING_STATUS.CheckIn
              }
            >
              Edit package
            </Button>
          ) : null}
        </>
      )}
    </>
  );

  const renderFooterToolbar = () => {
    /*
    Register PT: !isAfterRegistration
      (Total amount - Remaining cost - Register btn)
    Patient Registration: 
      - Btn View Patient Registration: isViewRegistration
      (Total amount - Remaining cost - Done btn)

      - Btn View Registration in Screening Progress: isPatientRegistration
      (Total amount - Remaining cost - Done btn)

      - Btn Update Progress: isScreeningProgress
      (Done btn)
    */
    return (
      <Toolbar style={{ borderTop: '2px solid #e1e1e1' }}>
        <Box
          width="100%"
          display="flex"
          justifyContent={isScreeningProgress ? 'flex-end' : 'space-between'}
          alignItems="center"
          py={1.75}
        >
          {isScreeningProgress ? null : (
            <Box display="flex" alignItems="center">
              <Box width={112}>
                <Typography variant="body2">Total amount:</Typography>
                <Typography variant="h6">
                  {isPaxCostCalculating || !paxCost ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : (
                    <strong>
                      {formatTotalPrice(
                        currentStaffType ? paxCost.totalCost : paxCost.subTotal,
                        '$'
                      )}
                    </strong>
                  )}
                </Typography>
              </Box>

              {isBillableProject(projectInfor?.billingType) && (
                <>
                  <Divider variant="middle" orientation="vertical" flexItem />
                  <Box width={112}>
                    <Typography variant="body2">Billable amount:</Typography>
                    <Typography variant="h6" color="error">
                      {isPaxCostCalculating || !paxCost ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : (
                        <strong>
                          {formatTotalPrice(paxCost.billableAmount, '$')}
                        </strong>
                      )}
                    </Typography>
                  </Box>
                </>
              )}

              <Divider variant="middle" orientation="vertical" flexItem />
              <Box width={112}>
                <Typography variant="body2">Remaining cost:</Typography>
                <Typography variant="h6" color="primary">
                  {isPaxCostCalculating || !paxCost ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : (
                    <strong>
                      {paxCost.remainingCost < 0 ? '-' : null}
                      {formatTotalPrice(Math.abs(paxCost.remainingCost), '$')}
                    </strong>
                  )}
                </Typography>
              </Box>

              {projectInfor?.billingType === BILLING_TYPE.Eligible &&
                !isAfterRegistration && (
                  <>
                    <Divider variant="middle" orientation="vertical" flexItem />
                    <IconButton
                      disabled={isPaxCostCalculating || !paxCost}
                      onClick={() => setShowEditBillableAmount(true)}
                      style={{ padding: 0 }}
                    >
                      <EditIcon />
                    </IconButton>
                  </>
                )}
            </Box>
          )}

          <Button
            size="large"
            color="primary"
            variant="contained"
            onClick={onSubmit}
          >
            {!isAfterRegistration ? 'Register' : 'Done'}
          </Button>
        </Box>
      </Toolbar>
    );
  };

  const onCloseInputValueModal = (e) => {
    e && e.preventDefault();
    setSelectedItem({});
    setShowInputValueModal(false);
  };

  const renderListActions = (selectedItem) => {
    let listActions = [];

    if (checkTestValue(selectedItem) && selectedItem.category !== 'Tests') {
      listActions.push({
        key: 'edit',
        icon: EditIcon,
        label: 'Edit value',
        styles: { width: 18, height: 18 },
        onClick: () => {
          setShowInputValueModal(true);
          setAnchorEl(null);
        },
      });
    }
    if (
      !(
        (selectedItem.isMandatory && isEmpty(selectedItem.testValue)) ||
        selectedItem.status !== STATUS_STATION.Awaiting ||
        selectedItem.category === 'Measurements'
      )
    ) {
      listActions.push({
        key: 'done',
        icon: CheckmarkCircleIcon,
        label: 'Mark as complete',
        styles: { width: 18, height: 18 },
        onClick: () => {
          handleClickStatusToDone(selectedItem);
          setAnchorEl(null);
        },
      });
    }
    return listActions;
  };

  return (
    <>
      <CustomPaperContainer
        header={
          <CustomHeader
            isSearch={false}
            title={label}
            renderButton={renderHeaderButtons}
          />
        }
      >
        <Container
          maxWidth={false}
          component={Paper}
          square
          variant="outlined"
          className={classes.container}
        >
          <Box
            flexDirection="column"
            className={classes.leftContainer}
            gridGap={16}
          >
            <GeneralInformation
              isFollowUp={isFollowUp}
              dataInfor={dataInfor}
              projectInfor={projectInfor}
              currentLabNumber={currentLabNumber}
              onOpenEditStaffType={() => setShowStaffType(!showStaffType)}
              isScreeningProgress={isScreeningProgress}
              lifestyleSurvey={lifestyleSurvey}
              customSurvey={customSurvey}
              consetForms={consetForms}
              totalLabNumbers={totalLabNumbers}
              totalTestCodes={totalTestCodes}
              isAfterRegistration={isAfterRegistration}
              staffType={currentStaffType}
              setShowAssignLabNumber={setShowAssignLabNumber}
              setLabNumbersMode={setLabNumbersMode}
              onAssignLabNumber={onAssignLabNumber}
            />
          </Box>
          <Box className={classes.rightContainer}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography className={classes.title}>
                  <ClipboardIcon style={{ color: '#666666' }} />
                  {isScreeningProgress ? 'Stations' : 'Selected packages'}
                </Typography>
              </Grid>
              {isScreeningProgress && !isPatientRegistration ? (
                !isEmpty(lstStations) &&
                lstStations.map((item) => (
                  <Grid item xs={12} key={item.id}>
                    <ServicesTable
                      stationId={item.id}
                      stationName={item.name}
                      status={item.status}
                      queueId={item.queueId}
                      columns={COLUMN_SERVICES}
                      renderRow={renderRow}
                      data={item.lstTests}
                      onDoneAll={handleClickDoneAll}
                      onNotifyToPatient={handleNotifyToPatient}
                    />
                  </Grid>
                ))
              ) : (
                <>
                  {!isEmpty(formValue?.lstPackages) && (
                    <Grid item xs={12}>
                      <PackageItem
                        dataInfor={dataInfor}
                        isFollowUp={isFollowUp}
                        isRegisterPatient
                        listData={formValue?.lstPackages}
                        ignoredTests={formValue?.ignoredTests}
                        ignoredProfiles={formValue?.ignoredProfiles}
                        restrictedTestIds={restrictedTestIds}
                      />
                    </Grid>
                  )}

                  {!isEmpty(formValue.lstPackages) &&
                    (!isEmpty(formValue.lstProfiles) ||
                      !isEmpty(formValue.lstTests)) && (
                      <Grid item xs={12}>
                        <Divider light variant="middle" />
                      </Grid>
                    )}

                  {!isEmpty(formValue.lstProfiles) ||
                  (!isEmpty(formValue.lstProfiles) &&
                    !isEmpty(formValue.lstTests)) ? (
                    <Grid item xs={12}>
                      <AddOnsItem listData={newListProfiles} />
                    </Grid>
                  ) : !isEmpty(formValue.lstTests) ? (
                    <Grid item xs={12}>
                      <AddOnsItem listData={formValue.lstTests} />
                    </Grid>
                  ) : null}

                  <Grid
                    item
                    xs={12}
                    hidden={
                      isNil(subsidy) ||
                      isBillableProject(projectInfor?.billingType) ||
                      currentStaffType
                    }
                    style={{ paddingTop: 0, paddingBottom: 0 }}
                  >
                    <Divider light variant="middle" />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    hidden={
                      isNil(subsidy) ||
                      isBillableProject(projectInfor?.billingType) ||
                      currentStaffType
                    }
                  >
                    <Subsidy
                      subsidy={subsidy}
                      subsidyNote={subsidyNote}
                      onOpenEditSubsidy={() => {
                        setSubsidyMode('Edit');
                        setShowEditSubsidyModal(true);
                      }}
                      isAfterRegistration={isAfterRegistration}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        </Container>
        {renderFooterToolbar()}
      </CustomPaperContainer>

      {showStaffType && (
        <ModalStaffType
          open={showStaffType}
          onClose={() => setShowStaffType(false)}
          onSubmit={onEditStaffType}
          lstStaffTypeItems={lstStaffTypeItems}
          currentStaffType={currentStaffType}
          hasChooseStaffType={hasChooseStaffType}
        />
      )}

      {showAlertLabNumber && (
        <ModalPrintLabNumber
          open={showAlertLabNumber}
          onClose={() => setShowAlertLabNumber(false)}
          onPrint={handlePrintLabNumber}
          dataInfor={dataInfor}
          currentLabNumber={currentLabNumber}
          currentLabProvider={currentLabProvider}
        />
      )}
      {showInputValueModal && (
        <ModalInputValue
          open={showInputValueModal}
          onClose={onCloseInputValueModal}
          selectedItem={selectedItem}
          testCode={selectedItem.testCode}
          onSubmitMeasurement={onSumitMeasurementValue}
          onSubmitTest={onSumitOtherTestValue}
        />
      )}

      {showAssignLabNumber && (
        <ModalAssignLabNumber
          open={showAssignLabNumber}
          onClose={() => setShowAssignLabNumber(false)}
          onAssignLabNumber={onAssignLabNumber}
          onSubmitLabNumber={onSubmitLabNumber}
          currentLabNumber={currentLabNumber}
          currentLabProvider={currentLabProvider}
          labNumbersMode={labNumbersMode}
        />
      )}

      {showEditBillableAmount && (
        <ModalEditBillableAmount
          billableAmount={paxCost?.billableAmount}
          subTotal={paxCost?.subTotal}
          onClose={() => setShowEditBillableAmount(false)}
          onSubmit={(newBillableAmount) => {
            setBillableAmount(newBillableAmount);
            setShowEditBillableAmount(false);
          }}
          open={showEditBillableAmount}
        />
      )}

      {showEditSubsidyModal && (
        <ModalAddOrEditSubsidy
          subsidy={subsidy}
          subsidyMode={subsidyMode}
          subsidyNote={subsidyNote}
          onClose={() => setShowEditSubsidyModal(false)}
          onSubmit={(newSubsidy, newSubsidyNote) => {
            setSubsidy(newSubsidy);
            setSubsidyNote(newSubsidyNote);
            setShowEditSubsidyModal(false);
          }}
          open={showEditSubsidyModal}
        />
      )}

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {anchorEl && (
          <CustomMenuActions listActions={renderListActions(selectedItem)} />
        )}
      </Menu>
    </>
  );
};

export default ViewPatientRegistration;
